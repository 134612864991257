import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import StaticPageHeader from "components/StaticPageHeader";
import RegulationsContent from "components/RegulationsContent";

const CookiesPolicy = ({ data }) => {
  const content = data?.privacyPolicy?.content;

  const seo = data?.privacyPolicy?.seo;
  const schema = JSON.parse(data?.privacyPolicy?.seo?.schema?.raw);

  return (
    <Layout
      location="Polityka prywatności"
      seo={{ title: seo?.title, description: seo?.metaDesc, schema }}
      blue_header
    >
      <StaticPageHeader title="Polityka prywatności" />
      <RegulationsContent data={content} />
    </Layout>
  );
};

export default CookiesPolicy;

export const query = graphql`
  query privacyPolicy {
    privacyPolicy: wpPage(id: { eq: "cG9zdDo1MDkz" }) {
      content
      title
      seo {
        title
        metaDesc
        schema {
          raw
        }
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphUrl
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`;
