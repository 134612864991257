import "./styles.scss";

import React from "react";

const mainClass = "regulations-content";

const RegulationsContent = ({ data }) => {
  return (
    <div className={mainClass}>
      <div className="container" dangerouslySetInnerHTML={{ __html: data }} />
    </div>
  );
};

export default RegulationsContent;
